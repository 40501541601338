// ==========================================================================
// Plyr Captions
// TODO: Create as class
// ==========================================================================

import controls from './controls';
import support from './support';
import { dedupe } from './utils/arrays';
import browser from './utils/browser';
import {
  createElement,
  emptyElement,
  getAttributesFromSelector,
  insertAfter,
  removeElement,
  toggleClass,
} from './utils/elements';
import { on, triggerEvent } from './utils/events';
import fetch from './utils/fetch';
import i18n from './utils/i18n';
import is from './utils/is';
import { getHTML } from './utils/strings';
import { parseUrl } from './utils/urls';

const captions = {
  // Setup captions
  setup(providedHls = null) {
    // Requires UI support
    if (!this.supported.ui) {
      return;
    }

    let oldTracks = this.media.querySelectorAll('track');
    if (oldTracks.length) {
      for (let i = 0; i < oldTracks.length; i++) {
        oldTracks[i].remove();
      }
    }

    // Only Vimeo and HTML5 video supported at this point
    if (!this.isVideo || this.isYouTube || (this.isHTML5 && !support.textTracks)) {
      // Clear menu and hide
      if (
        is.array(this.config.controls) &&
        this.config.controls.includes('settings') &&
        this.config.settings.includes('captions')
      ) {
        controls.setCaptionsMenu.call(this);
      }

      return;
    }

    // Inject the container
    if (!is.element(this.elements.captions)) {
      this.elements.captions = createElement('div', getAttributesFromSelector(this.config.selectors.captions));

      insertAfter(this.elements.captions, this.elements.wrapper);
    }

    // Fix IE captions if CORS is used
    // Fetch captions and inject as blobs instead (data URIs not supported!)
    if (browser.isIE && window.URL) {
      const elements = this.media.querySelectorAll('track');

      Array.from(elements).forEach((track) => {
        const src = track.getAttribute('src');
        const url = parseUrl(src);

        if (
          url !== null &&
          url.hostname !== window.location.href.hostname &&
          ['http:', 'https:'].includes(url.protocol)
        ) {
          fetch(src, 'blob')
            .then((blob) => {
              track.setAttribute('src', window.URL.createObjectURL(blob));
            })
            .catch(() => {
              removeElement(track);
            });
        }
      });
    }

    // Get and set initial data
    // The "preferred" options are not realized unless / until the wanted language has a match
    // * languages: Array of user's browser languages.
    // * language:  The language preferred by user settings or config
    // * active:    The state preferred by user settings or config
    // * toggled:   The real captions state

    const browserLanguages = navigator.languages || [navigator.language || navigator.userLanguage || 'en'];
    const languages = dedupe(browserLanguages.map((language) => language.split('-')[0]));
    let language = (this.storage.get('language') || this.config.captions.language || 'auto').toLowerCase();

    // Use first browser language when language is 'auto'
    if (language === 'auto') {
      [language] = languages;
    }

    language = language.length === 3 ? captions.correctLanguageCode.call(this, language) : language;

    this.hls = providedHls !== null ? providedHls : this.config.hls;

    setTimeout(() => {
      const subtitles = this.hls == null ? this.media.textTracks : this.hls.subtitleTracks; // null is iPhone buil-in hls
      if (this.hls == null) {
        this.media.textTracks.onaddtrack = (event) => { captions.update.call(this, event) };
      }
      for (let i = 0; i < subtitles.length; i++) {
        let track = document.createElement('track');
        let defaultVal = captions.correctLanguageCode.call(this, subtitles[i].lang) == language ? true : false;
        let trackURL = subtitles[i].url.split('/');
        trackURL = subtitles[i].url.replace( trackURL[trackURL.length - 1], subtitles[i].lang.toUpperCase() + '.vtt' );

        Object.assign(track, {
          label: subtitles[i].name,
          kind: 'captions',
          srclang: captions.correctLanguageCode.call(this, subtitles[i].lang),
          default: defaultVal,
          src: trackURL,
        });
        this.media.appendChild(track);
      }

      let active = this.storage.get('captions');
      if (!is.boolean(active)) {
        ({ active } = this.config.captions);
      }

      Object.assign(this.captions, {
        toggled: false,
        active,
        language,
        languages,
      });

      const tracks = captions.getTracks.call(this, true);

      let languageExists = false;
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].language === language) {
          languageExists = true;
          break;
        }
      }

      // Handle tracks (add event listener and "pseudo"-default)
      for (let i = 0; i < tracks.length; i++ ) {
        if ( tracks[i].language == language ) {
          setTimeout(() => {
            tracks[i].mode = browser.isIPhone ? 'showing' : 'hidden';
          }, 1000);
        } else {
          tracks[i].mode = 'disabled';
          setTimeout(() => {
            tracks[i].mode = 'disabled';
          }, 1000);
        }
        on.call(this, tracks[i], 'cuechange', () => captions.updateCues.call(this));
      }

      if (languageExists) {
        captions.setLanguage.call(this, language);
        captions.toggle.call(this, active && languageExists);
      }

      // Fix subtitles for missing languages
      if (!languageExists && this.media.textTracks.length) {
        let newLanguage = this.media.textTracks[0].language;
        this.storage.set({ language: newLanguage });
        captions.setLanguage.call(this, newLanguage);
        captions.toggle.call(this, active);
      }

      controls.setCaptionsMenu.call(this);

      // Enable or disable captions based on track length
      if (this.elements) {
        toggleClass(this.elements.container, this.config.classNames.captions.enabled, !is.empty(tracks));
      }

    }, 0);

  },

  // Update available language options in settings based on tracks
  update(event) {
    const tracks = captions.getTracks.call(this, true);
    let language = this.captions.language;
    let languageExists = false;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].language === language) {
        languageExists = true;
        break;
      }
    }

    // Handle tracks (add event listener and "pseudo"-default)
    for (let i = 0; i < tracks.length; i++ ) {
      if ( tracks[i].language == language ) {
        setTimeout(() => {
          tracks[i].mode = browser.isIPhone ? 'showing' : 'hidden';
        }, 1000);
      } else {
        tracks[i].mode = 'disabled';
        setTimeout(() => {
          tracks[i].mode = 'disabled';
        }, 1000);
      }
      on.call(this, tracks[i], 'cuechange', () => captions.updateCues.call(this));
    }

    if (languageExists) {
      captions.setLanguage.call(this, language);
      captions.toggle.call(this, this.captions.active && languageExists);
    }

    // Fix subtitles for missing languages
    if (!languageExists && this.media.textTracks.length) {
      let newLanguage = this.media.textTracks[0].language;
      this.storage.set({ language: newLanguage });
      captions.setLanguage.call(this, newLanguage);
      captions.toggle.call(this, this.captions.active);
    }

    controls.setCaptionsMenu.call(this);

    // Enable or disable captions based on track length
    if (this.elements) {
      toggleClass(this.elements.container, this.config.classNames.captions.enabled, !is.empty(tracks));
    }

  },

  // Toggle captions display
  // Used internally for the toggleCaptions method, with the passive option forced to false
  toggle(input, passive = true) {
    // If there's no full support
    if (!this.supported.ui) {
      return;
    }

    const { toggled } = this.captions; // Current state
    const activeClass = this.config.classNames.captions.active;
    // Get the next state
    // If the method is called without parameter, toggle based on current value
    const active = is.nullOrUndefined(input) ? !toggled : input;
    const tracks = captions.getTracks.call(this);

    // Update state and trigger event
    if (active !== toggled) {
      // When passive, don't override user preferences
      if (!passive) {
        this.captions.active = active;
        this.storage.set({ captions: active });
      }

      // Force language if the call isn't passive and there is no matching language to toggle to
      if (!this.language && active && !passive) {
        const track = captions.findTrack.call(this, [this.captions.language, ...this.captions.languages], true);

        // Override user preferences to avoid switching languages if a matching track is added
        this.captions.language = track.language;

        // Set caption, but don't store in localStorage as user preference
        captions.set.call(this, tracks.indexOf(track));
        return;
      }

      // Toggle button if it's enabled
      if (this.elements.buttons.captions) {
        this.elements.buttons.captions.pressed = active;
      }

      // Add class hook
      toggleClass(this.elements.container, activeClass, active);

      this.captions.toggled = active;

      if (!active) {
        for (let i = 0; i < tracks.length; i++) {
          tracks[i].mode = 'disabled';
        }
      } else {
        this.captions.currentTrackNode.mode = browser.isIPhone ? 'showing' : 'hidden';
      }

      // Update settings menu
      controls.updateSetting.call(this, 'captions');

      // Trigger event (not used internally)
      triggerEvent.call(this, this.media, active ? 'captionsenabled' : 'captionsdisabled');
    }

    // Wait for the call stack to clear before setting mode='hidden'
    // on the active track - forcing the browser to download it
    setTimeout(() => {
      if (active && this.captions.toggled) {
        this.captions.currentTrackNode.mode = browser.isIPhone ? 'showing' : 'hidden';
      }
    });
  },

  // Set captions by track index
  // Used internally for the currentTrack setter with the passive option forced to false
  set(index, passive = true) {
    const tracks = captions.getTracks.call(this);

    // Disable captions if setting to -1
    if (index === -1) {
      captions.toggle.call(this, false, passive);
      return;
    }

    if (!is.number(index)) {
      this.debug.warn('Invalid caption argument', index);
      return;
    }

    if (!(index in tracks)) {
      this.debug.warn('Track not found', index);
      return;
    }

    if (this.captions.currentTrack !== index) {
      this.captions.currentTrack = index;
      const track = tracks[index];
      const { language } = track || {};

      for (let i = 0; i < tracks.length; i++ ) {
        if ( i == index ) {
          setTimeout(() => {
            tracks[i].mode = browser.isIPhone ? 'showing' : 'hidden';
          }, 1000);
        } else {
          tracks[i].mode = 'disabled';
        }
        on.call(this, tracks[i], 'cuechange', () => captions.updateCues.call(this));
      }

      // Store reference to node for invalidation on remove
      this.captions.currentTrackNode = track;

      // Update settings menu
      controls.updateSetting.call(this, 'captions');

      // When passive, don't override user preferences
      if (!passive) {
        this.captions.language = language;
        this.storage.set({ language });
      }

      // Handle Vimeo captions
      if (this.isVimeo) {
        this.embed.enableTextTrack(language);
      }

      // Trigger event
      triggerEvent.call(this, this.media, 'languagechange');
    }

    // Show captions
    captions.toggle.call(this, true, passive);

    if (this.isHTML5 && this.isVideo) {
      // If we change the active track while a cue is already displayed we need to update it
      captions.updateCues.call(this);
    }
  },

  correctLanguageCode(languageCode) {
    switch (languageCode) {
      case 'eng':
        return 'en';
        break;
      case 'spa':
        return 'es';
        break;
      case 'fra':
        return 'fr';
        break;
      case 'ita':
        return 'it';
        break;
      case 'deu':
        return 'de';
        break;
      case 'por':
        return 'pt';
        break;
      case 'swe':
        return 'sv';
      case 'pol':
        return 'pl';
      case 'nld':
        return 'nl';
        break;
      case 'ind':
        return 'id';
        break;
      case 'jpn':
        return 'jp';
        break;
      case 'tur':
        return 'tk';
        break;
      default:
        return 'en';
        break;
    }
  },

  // Set captions by language
  // Used internally for the language setter with the passive option forced to false
  setLanguage(input, passive = true) {
    if (!is.string(input)) {
      this.debug.warn('Invalid language argument', input);
      return;
    }
    // Normalize
    const language = input.toLowerCase();
    this.captions.language = language;

    // Set currentTrack
    const tracks = captions.getTracks.call(this);
    const track = captions.findTrack.call(this, [language]);
    captions.set.call(this, tracks.indexOf(track), passive);
  },

  // Get current valid caption tracks
  // If update is false it will also ignore tracks without metadata
  // This is used to "freeze" the language options when captions.update is false
  getTracks(update = false) {
    // Handle media or textTracks missing or null
    let tracks = this.media.textTracks;
    let tracksArr = [];
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind == 'captions' || tracks[i].kind == 'subtitles') {
        tracksArr.push(tracks[i]);
      }
    }

    // For HTML5, use cache instead of current tracks when it exists (if captions.update is false)
    // Filter out removed tracks and tracks that aren't captions/subtitles (for example metadata)
    return tracksArr;
  },

  // Match tracks based on languages and get the first
  findTrack(languages, force = false) {
    const tracks = captions.getTracks.call(this);

    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].language === languages[0]) {
        return tracks[i];
      }
    }

    // If no match is found but is required, get first
    return tracks[0];
  },

  // Get the current track
  getCurrentTrack() {
    return captions.getTracks.call(this)[this.currentTrack];
  },

  // Get UI label for track
  getLabel(track) {
    let currentTrack = track;

    if (!is.track(currentTrack) && support.textTracks && this.captions.toggled) {
      currentTrack = captions.getCurrentTrack.call(this);
    }

    if (is.track(currentTrack)) {
      if (!is.empty(currentTrack.label)) {
        return currentTrack.label;
      }

      if (!is.empty(currentTrack.language)) {
        return track.language.toUpperCase();
      }

      return i18n.get('enabled', this.config);
    }

    return i18n.get('disabled', this.config);
  },

  // Update captions using current track's active cues
  // Also optional array argument in case there isn't any track (ex: vimeo)
  updateCues(input) {
    // Requires UI
    if (!this.supported.ui) {
      return;
    }

    if (!is.element(this.elements.captions)) {
      this.debug.warn('No captions element to render to');
      return;
    }

    // Only accept array or empty input
    if (!is.nullOrUndefined(input) && !Array.isArray(input)) {
      this.debug.warn('updateCues: Invalid input', input);
      return;
    }

    let cues = input;

    // Get cues from track
    if (!cues) {
      const track = captions.getCurrentTrack.call(this);
      cues = Array.from((track || {}).activeCues || [])
        .map((cue) => cue.getCueAsHTML())
        .map(getHTML);
    }

    // Set new caption text
    const content = cues.map((cueText) => cueText.trim()).join('\n');
    const changed = content !== this.elements.captions.innerHTML;

    if (changed) {
      // Empty the container and create a new child element
      emptyElement(this.elements.captions);
      const caption = createElement('span', getAttributesFromSelector(this.config.selectors.caption));
      caption.innerHTML = content;
      this.elements.captions.appendChild(caption);

      // Trigger event
      triggerEvent.call(this, this.media, 'cuechange');
    }
  },
};

export default captions;
