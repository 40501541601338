// ==========================================================================
// Plyr Autoplay
// TODO: Create as class
// ==========================================================================

import {
  createElement,
  emptyElement,
  getAttributesFromSelector,
  insertAfter,
  removeElement,
  toggleClass,
} from './utils/elements';
import is from './utils/is';

const autoplayVideos = {
  // Setup autoplay
  setup() {
    // Requires UI support
    if (!this.supported.ui) {
      return;
    }

    let active = this.storage.get('autoplayVideos');
    if (!is.boolean(active)) {
      active = true;
    }
    let toggled = active;
    Object.assign(this.autoplayVideos, {
      toggled,
      active,
    });

    // Toggle button if it's enabled
    if (this.elements.buttons.autoplayVideos) {
      this.elements.buttons.autoplayVideos.pressed = active;
    }
  },

  // Toggle autoplayVideos display
  // Used internally for the toggleAutoplay method, with the passive option forced to false
  toggle(input, passive = true) {
    // If there's no full support
    if (!this.supported.ui) {
      return;
    }

    const { toggled } = this.autoplayVideos; // Current state
    const activeClass = this.config.classNames.autoplayVideos.active;
    // Get the next state
    // If the method is called without parameter, toggle based on current value
    const active = is.nullOrUndefined(input) ? !toggled : input;

    // Update state and trigger event
    if (active !== toggled) {
      // When passive, don't override user preferences
      if (!passive) {
        this.autoplayVideos.active = active;
        this.storage.set({ autoplayVideos: active });
      }

      // Toggle button if it's enabled
      if (this.elements.buttons.autoplayVideos) {
        this.elements.buttons.autoplayVideos.pressed = active;
      }

      // Add class hook
      toggleClass(this.elements.container, activeClass, active);

      this.autoplayVideos.toggled = active;
    }
  },
};

export default autoplayVideos;
